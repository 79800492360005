/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Answer } from '../models/Answer';
import type { AnswerCreateDto } from '../models/AnswerCreateDto';
import type { AnswerExportDto } from '../models/AnswerExportDto';
import type { AnswerUpdateDto } from '../models/AnswerUpdateDto';
import type { AuthUser } from '../models/AuthUser';
import type { AutoScoreDto } from '../models/AutoScoreDto';
import type { Chapter } from '../models/Chapter';
import type { ChapterCreateDto } from '../models/ChapterCreateDto';
import type { ChapterExportDto } from '../models/ChapterExportDto';
import type { ChapterMoveDto } from '../models/ChapterMoveDto';
import type { ChapterUpdateDto } from '../models/ChapterUpdateDto';
import type { CreateResponseUserDto } from '../models/CreateResponseUserDto';
import type { CreateRevisionDto } from '../models/CreateRevisionDto';
import type { DeleteResult } from '../models/DeleteResult';
import type { EmailCommunicationEntity } from '../models/EmailCommunicationEntity';
import type { EmailContactsDto } from '../models/EmailContactsDto';
import type { EmailDto } from '../models/EmailDto';
import type { EmailUpdateDto } from '../models/EmailUpdateDto';
import type { MarketingContactDto } from '../models/MarketingContactDto';
import type { Org } from '../models/Org';
import type { OrgCreateDto } from '../models/OrgCreateDto';
import type { OrgExportDto } from '../models/OrgExportDto';
import type { Page } from '../models/Page';
import type { PageCreateDto } from '../models/PageCreateDto';
import type { PageExportDto } from '../models/PageExportDto';
import type { PageMoveDto } from '../models/PageMoveDto';
import type { PageUpdateDto } from '../models/PageUpdateDto';
import type { PermissionDto } from '../models/PermissionDto';
import type { Project } from '../models/Project';
import type { ProjectCreateDto } from '../models/ProjectCreateDto';
import type { ProjectExportDto } from '../models/ProjectExportDto';
import type { ProjectTreeDto } from '../models/ProjectTreeDto';
import type { ProjectTreeItem } from '../models/ProjectTreeItem';
import type { ProjectUpdateDto } from '../models/ProjectUpdateDto';
import type { Question } from '../models/Question';
import type { QuestionAnswerCreateDto } from '../models/QuestionAnswerCreateDto';
import type { QuestionCreateDto } from '../models/QuestionCreateDto';
import type { QuestionDto } from '../models/QuestionDto';
import type { QuestionExportDto } from '../models/QuestionExportDto';
import type { QuestionMoveDto } from '../models/QuestionMoveDto';
import type { Questionnaire } from '../models/Questionnaire';
import type { QuestionnaireCreateDto } from '../models/QuestionnaireCreateDto';
import type { QuestionnaireExportDto } from '../models/QuestionnaireExportDto';
import type { QuestionnaireUpdateDto } from '../models/QuestionnaireUpdateDto';
import type { QuestionScoreRangesDto } from '../models/QuestionScoreRangesDto';
import type { QuestionUpdateDto } from '../models/QuestionUpdateDto';
import type { Response } from '../models/Response';
import type { ResponseAnswersCreateDto } from '../models/ResponseAnswersCreateDto';
import type { ResponseCreateDto } from '../models/ResponseCreateDto';
import type { ResponseDto } from '../models/ResponseDto';
import type { ResponseExportDto } from '../models/ResponseExportDto';
import type { ResponseInviteDto } from '../models/ResponseInviteDto';
import type { ResponseOpenDto } from '../models/ResponseOpenDto';
import type { ResponseScoreDto } from '../models/ResponseScoreDto';
import type { ResponseScoresExportDto } from '../models/ResponseScoresExportDto';
import type { ResponseSummaryDto } from '../models/ResponseSummaryDto';
import type { ResponseUpdateDto } from '../models/ResponseUpdateDto';
import type { ResponseUser } from '../models/ResponseUser';
import type { RevisionCreationJob } from '../models/RevisionCreationJob';
import type { RevisionList } from '../models/RevisionList';
import type { RoleAssignToUsersDto } from '../models/RoleAssignToUsersDto';
import type { RoleDto } from '../models/RoleDto';
import type { SampleQuestionCreateDto } from '../models/SampleQuestionCreateDto';
import type { Score } from '../models/Score';
import type { ScoreCategory } from '../models/ScoreCategory';
import type { ScoreCreateDto } from '../models/ScoreCreateDto';
import type { ScoredItem } from '../models/ScoredItem';
import type { ScoredItemCreateDto } from '../models/ScoredItemCreateDto';
import type { ScoredItemExportDto } from '../models/ScoredItemExportDto';
import type { ScoredItemUpdateDto } from '../models/ScoredItemUpdateDto';
import type { ScoreRangesExportDto } from '../models/ScoreRangesExportDto';
import type { SummarizedRange } from '../models/SummarizedRange';
import type { UpdateResult } from '../models/UpdateResult';
import type { User } from '../models/User';
import type { UserCreateDto } from '../models/UserCreateDto';
import type { UserExportDto } from '../models/UserExportDto';
import type { UserRolesDto } from '../models/UserRolesDto';
import type { UserUpdateDto } from '../models/UserUpdateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * @returns string
     * @throws ApiError
     */
    public static appControllerGetHealth(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/health',
        });
    }
    /**
     * @returns AuthUser return user
     * @throws ApiError
     */
    public static appControllerTestAuth(): CancelablePromise<AuthUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth_test',
        });
    }
    /**
     * @param pageUuid
     * @param toChapterUuid
     * @returns Page the created page record
     * @throws ApiError
     */
    public static contentControllerCopyPageToChapter(
        pageUuid: string,
        toChapterUuid: string,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/content/copy/page/{pageUuid}/chapter/{toChapterUuid}',
            path: {
                'pageUuid': pageUuid,
                'toChapterUuid': toChapterUuid,
            },
        });
    }
    /**
     * @param pageUuid
     * @param toQuestionnaireUuid
     * @param revision
     * @returns Page the created page record
     * @throws ApiError
     */
    public static contentControllerCopyPageToQuestionnaire(
        pageUuid: string,
        toQuestionnaireUuid: string,
        revision: number,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/content/copy/page/{pageUuid}/questionnaire/{toQuestionnaireUuid}/revision/{revision}',
            path: {
                'pageUuid': pageUuid,
                'toQuestionnaireUuid': toQuestionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @param questionUuid
     * @param toPageUuid
     * @returns Question the created question record
     * @throws ApiError
     */
    public static contentControllerCopyQuestionToPage(
        questionUuid: string,
        toPageUuid: string,
    ): CancelablePromise<Question> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/content/copy/question/{questionUuid}/{toPageUuid}',
            path: {
                'questionUuid': questionUuid,
                'toPageUuid': toPageUuid,
            },
        });
    }
    /**
     * @returns UserExportDto Export all users
     * @throws ApiError
     */
    public static usersControllerExport(): CancelablePromise<Array<UserExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/export',
        });
    }
    /**
     * @param requestBody
     * @returns User The created user record
     * @throws ApiError
     */
    public static usersControllerCreate(
        requestBody: UserCreateDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param email
     * @returns UserRolesDto The roles which the user has
     * @throws ApiError
     */
    public static usersControllerGetUserByEmailRoles(
        email: string,
    ): CancelablePromise<UserRolesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/email/{email}/roles',
            path: {
                'email': email,
            },
        });
    }
    /**
     * @param email
     * @returns User The user record
     * @throws ApiError
     */
    public static usersControllerGetByEmail(
        email: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/email/{email}',
            path: {
                'email': email,
            },
        });
    }
    /**
     * @param orgUuid
     * @returns User The users in the company
     * @throws ApiError
     */
    public static usersControllerGetByOrg(
        orgUuid: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/org/{orgUuid}',
            path: {
                'orgUuid': orgUuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns User The updated user
     * @throws ApiError
     */
    public static usersControllerUpdate(
        uuid: string,
        requestBody: UserUpdateDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param projectUuid
     * @returns User The users who have responses to the project questionnaire
     * @throws ApiError
     */
    public static usersControllerUserResponsesForProject(
        projectUuid: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/project/{projectUuid}',
            path: {
                'projectUuid': projectUuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns UserRolesDto The roles which the user has
     * @throws ApiError
     */
    public static usersControllerGetUserRoles(
        uuid: string,
    ): CancelablePromise<UserRolesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{uuid}/roles',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns User The user record
     * @throws ApiError
     */
    public static usersControllerGetByUuid(
        uuid: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/uuid/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param email email of user
     * @param firstName firstName of user
     * @param surname surname of user
     * @param orgUuid orgUuid of user
     * @returns User matching user records
     * @throws ApiError
     */
    public static usersControllerFind(
        email?: string,
        firstName?: string,
        surname?: string,
        orgUuid?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/find',
            query: {
                'email': email,
                'firstName': firstName,
                'surname': surname,
                'orgUuid': orgUuid,
            },
        });
    }
    /**
     * @returns OrgExportDto Export all organizations
     * @throws ApiError
     */
    public static orgsControllerExport(): CancelablePromise<Array<OrgExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/export',
        });
    }
    /**
     * @param requestBody
     * @returns Org
     * @throws ApiError
     */
    public static orgsControllerCreate(
        requestBody: OrgCreateDto,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orgs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Org list of all organisation records
     * @throws ApiError
     */
    public static orgsControllerList(): CancelablePromise<Array<Org>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs',
        });
    }
    /**
     * @param uuid
     * @returns Org The organisation record
     * @throws ApiError
     */
    public static orgsControllerGet(
        uuid: string,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orgs/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param identity
     * @returns any
     * @throws ApiError
     */
    public static oryAuthPluginControllerProvidePermissions(
        identity: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth/permissions',
            query: {
                'identity': identity,
            },
        });
    }
    /**
     * @returns PermissionDto list of available permissions
     * @throws ApiError
     */
    public static rolesControllerGetPermissions(): CancelablePromise<Array<PermissionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/permissions',
        });
    }
    /**
     * @returns RoleDto list of available roles
     * @throws ApiError
     */
    public static rolesControllerGetRoles(): CancelablePromise<Array<RoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/list',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static rolesControllerGetAll(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
        });
    }
    /**
     * @param email
     * @param role
     * @returns any
     * @throws ApiError
     */
    public static rolesControllerAssignRoleToUser(
        email: string,
        role: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/roles/user/{email}/{role}',
            path: {
                'email': email,
                'role': role,
            },
        });
    }
    /**
     * @param email
     * @param role
     * @returns any
     * @throws ApiError
     */
    public static rolesControllerDeleteUserRole(
        email: string,
        role: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/user/{email}/{role}',
            path: {
                'email': email,
                'role': role,
            },
        });
    }
    /**
     * @param roleId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static rolesControllerAssignRoleToUsers(
        roleId: string,
        requestBody: RoleAssignToUsersDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/roles/{roleId}',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ProjectExportDto Export all projects
     * @throws ApiError
     */
    public static projectsControllerExport(): CancelablePromise<Array<ProjectExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/export',
        });
    }
    /**
     * @param requestBody
     * @returns Project the created project record
     * @throws ApiError
     */
    public static projectsControllerCreate(
        requestBody: ProjectCreateDto,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ProjectTreeDto all project records
     * @throws ApiError
     */
    public static projectsControllerList(): CancelablePromise<Array<ProjectTreeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects',
        });
    }
    /**
     * @param questionaireUuid
     * @returns Project all project records using the specified questionnaire
     * @throws ApiError
     */
    public static projectsControllerQuestionnaireProjects(
        questionaireUuid: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/questionnaire/{questionaireUuid}',
            path: {
                'questionaireUuid': questionaireUuid,
            },
        });
    }
    /**
     * @returns ProjectTreeItem projects to which the authenticated user has access (more precisely, the users company)
     * @throws ApiError
     */
    public static projectsControllerUserAccessibleProjects(): CancelablePromise<Array<ProjectTreeItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/user/accessible',
        });
    }
    /**
     * @param uuid
     * @returns ProjectTreeItem projects to which users in this company have access
     * @throws ApiError
     */
    public static projectsControllerOrgAccessibleProjects(
        uuid: string,
    ): CancelablePromise<Array<ProjectTreeItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/org/{uuid}/accessible',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns Project the project record
     * @throws ApiError
     */
    public static projectsControllerGet(
        uuid: string,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static projectsControllerUpdate(
        uuid: string,
        requestBody: ProjectUpdateDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/projects/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static projectsControllerRemove(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param orgUuid
     * @returns Project project records belonging directly to the company
     * @throws ApiError
     */
    public static projectsControllerGetOrgProjects(
        orgUuid: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/org/{orgUuid}',
            path: {
                'orgUuid': orgUuid,
            },
        });
    }
    /**
     * @returns QuestionnaireExportDto Export all questionnaires
     * @throws ApiError
     */
    public static questionnairesControllerExport(): CancelablePromise<Array<QuestionnaireExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questionnaires/export',
        });
    }
    /**
     * @param uuid
     * @returns Questionnaire The questionnaire record
     * @throws ApiError
     */
    public static questionnairesControllerGet(
        uuid: string,
    ): CancelablePromise<Questionnaire> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questionnaires/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static questionnairesControllerUpdate(
        uuid: string,
        requestBody: QuestionnaireUpdateDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/questionnaires/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Questionnaire all questionnaire records
     * @throws ApiError
     */
    public static questionnairesControllerList(): CancelablePromise<Array<Questionnaire>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questionnaires',
        });
    }
    /**
     * @param requestBody
     * @returns Questionnaire
     * @throws ApiError
     */
    public static questionnairesControllerCreate(
        requestBody: QuestionnaireCreateDto,
    ): CancelablePromise<Questionnaire> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/questionnaires',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ResponseExportDto Export all responses
     * @throws ApiError
     */
    public static responsesControllerExport(): CancelablePromise<Array<ResponseExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/export',
        });
    }
    /**
     * @param responseUuid
     * @returns ResponseOpenDto the question structure required by survey.js
     * @throws ApiError
     */
    public static responsesControllerGetResponseQuestions(
        responseUuid: string,
    ): CancelablePromise<ResponseOpenDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/{responseUuid}/questions',
            path: {
                'responseUuid': responseUuid,
            },
        });
    }
    /**
     * @param questionnaireId
     * @returns ResponseOpenDto the UUID of a new response record created for response to a public questionnaire
     * @throws ApiError
     */
    public static responsesControllerGetNewResponseForQuestionnaire(
        questionnaireId: string,
    ): CancelablePromise<ResponseOpenDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/questionnaire/{questionnaireId}',
            path: {
                'questionnaireId': questionnaireId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any the response record updated with its isInvited flag set
     * @throws ApiError
     */
    public static responsesControllerInvite(
        requestBody: ResponseInviteDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/responses/invite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ResponseUser the response-user record
     * @throws ApiError
     */
    public static responsesControllerAddUserToResponse(
        requestBody: CreateResponseUserDto,
    ): CancelablePromise<ResponseUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/responses/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns Response the created response record
     * @throws ApiError
     */
    public static responsesControllerCreate(
        requestBody: ResponseCreateDto,
    ): CancelablePromise<Response> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/responses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param projectUuid
     * @returns Response responses to the project
     * @throws ApiError
     */
    public static responsesControllerProjectResponses(
        projectUuid: string,
    ): CancelablePromise<Array<Response>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/project/{projectUuid}',
            path: {
                'projectUuid': projectUuid,
            },
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public static responsesControllerGetUrl(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/url',
        });
    }
    /**
     * @param uuid
     * @returns string
     * @throws ApiError
     */
    public static responsesControllerGetUrlForResponse(
        uuid: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/{uuid}/url',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns Response the response record
     * @throws ApiError
     */
    public static responsesControllerGet(
        uuid: string,
    ): CancelablePromise<Response> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/responses/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static responsesControllerRemove(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/responses/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns Response the updated response record
     * @throws ApiError
     */
    public static responsesControllerUpdateResponse(
        uuid: string,
        requestBody: ResponseUpdateDto,
    ): CancelablePromise<Response> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/responses/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param responseUuid
     * @param userUuid
     * @returns any
     * @throws ApiError
     */
    public static responsesControllerRemoveUser(
        responseUuid: string,
        userUuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/responses/{responseUuid}/user/{userUuid}',
            path: {
                'responseUuid': responseUuid,
                'userUuid': userUuid,
            },
        });
    }
    /**
     * @returns PageExportDto Export all Pages
     * @throws ApiError
     */
    public static pagesControllerExport(): CancelablePromise<Array<PageExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/export',
        });
    }
    /**
     * @param requestBody
     * @returns Page
     * @throws ApiError
     */
    public static pagesControllerCreate(
        requestBody: PageCreateDto,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param questionnaireUUid
     * @param revision
     * @returns Page the pages in the questionnaire revision
     * @throws ApiError
     */
    public static pagesControllerGetQuestionnaireRevisionPages(
        questionnaireUUid: string,
        revision: number,
    ): CancelablePromise<Array<Page>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/questionnaire/{questionnaireUUid}/revision/{revision}',
            path: {
                'questionnaireUUid': questionnaireUUid,
                'revision': revision,
            },
        });
    }
    /**
     * @param questionnaireUUid
     * @returns Page the pages in the questionnaire
     * @throws ApiError
     */
    public static pagesControllerGetQuestionnairePages(
        questionnaireUUid: string,
    ): CancelablePromise<Array<Page>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/questionnaire/{questionnaireUUid}',
            path: {
                'questionnaireUUid': questionnaireUUid,
            },
        });
    }
    /**
     * @param uuid
     * @returns Page the page record
     * @throws ApiError
     */
    public static pagesControllerGet(
        uuid: string,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns Page the updated page record
     * @throws ApiError
     */
    public static pagesControllerUpdate(
        uuid: string,
        requestBody: PageUpdateDto,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/pages/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ChapterExportDto Export all chapters
     * @throws ApiError
     */
    public static chaptersControllerExport(): CancelablePromise<Array<ChapterExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chapters/export',
        });
    }
    /**
     * @param requestBody
     * @returns Chapter
     * @throws ApiError
     */
    public static chaptersControllerCreate(
        requestBody: ChapterCreateDto,
    ): CancelablePromise<Chapter> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/chapters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uuid
     * @returns Chapter the chapter record
     * @throws ApiError
     */
    public static chaptersControllerGet(
        uuid: string,
    ): CancelablePromise<Chapter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chapters/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns DeleteResult delete chapter
     * @throws ApiError
     */
    public static chaptersControllerDelete(
        uuid: string,
    ): CancelablePromise<DeleteResult> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/chapters/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns UpdateResult update a chapter
     * @throws ApiError
     */
    public static chaptersControllerUpdate(
        uuid: string,
        requestBody: ChapterUpdateDto,
    ): CancelablePromise<UpdateResult> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/chapters/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param questionnaireUuid
     * @param revision
     * @returns Chapter chapters in the questionnaire and revision
     * @throws ApiError
     */
    public static chaptersControllerQuestionnaireRevisionChapters(
        questionnaireUuid: string,
        revision: number,
    ): CancelablePromise<Array<Chapter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chapters/questionnaire/{questionnaireUuid}/revision/{revision}',
            path: {
                'questionnaireUuid': questionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @param questionnaireUuid
     * @returns Chapter chapters in the questionnaire
     * @throws ApiError
     */
    public static chaptersControllerQuestionnaireChapters(
        questionnaireUuid: string,
    ): CancelablePromise<Array<Chapter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chapters/questionnaire/{questionnaireUuid}',
            path: {
                'questionnaireUuid': questionnaireUuid,
            },
        });
    }
    /**
     * @returns AnswerExportDto Export all answers
     * @throws ApiError
     */
    public static answersControllerExport(): CancelablePromise<Array<AnswerExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/answers/export',
        });
    }
    /**
     * @param pageUuid
     * @param requestBody
     * @returns Answer the created answer records
     * @throws ApiError
     */
    public static answersControllerAddMatrixColumn(
        pageUuid: string,
        requestBody: QuestionAnswerCreateDto,
    ): CancelablePromise<Array<Answer>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/answers/page/{pageUuid}',
            path: {
                'pageUuid': pageUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pageUuid
     * @returns Answer the possible answers for the questions on a page
     * @throws ApiError
     */
    public static answersControllerGetAnswersForPage(
        pageUuid: string,
    ): CancelablePromise<Array<Answer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/answers/page/{pageUuid}',
            path: {
                'pageUuid': pageUuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Answer the created answer record
     * @throws ApiError
     */
    public static answersControllerCreate(
        requestBody: AnswerCreateDto,
    ): CancelablePromise<Answer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/answers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param questionUuid
     * @returns Answer the possible answers for a question
     * @throws ApiError
     */
    public static answersControllerGetAnswersForQuestion(
        questionUuid: string,
    ): CancelablePromise<Array<Answer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/answers/question/{questionUuid}',
            path: {
                'questionUuid': questionUuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns Answer the answer record
     * @throws ApiError
     */
    public static answersControllerGet(
        uuid: string,
    ): CancelablePromise<Answer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/answers/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns Answer
     * @throws ApiError
     */
    public static answersControllerUpdate(
        uuid: string,
        requestBody: AnswerUpdateDto,
    ): CancelablePromise<Answer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/answers/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns QuestionExportDto Exports all Questions
     * @throws ApiError
     */
    public static questionsControllerExport(): CancelablePromise<Array<QuestionExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questions/export',
        });
    }
    /**
     * @param requestBody
     * @returns Question the created question record
     * @throws ApiError
     */
    public static questionsControllerCreate(
        requestBody: QuestionCreateDto,
    ): CancelablePromise<Question> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/questions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uuid
     * @returns Question The question record
     * @throws ApiError
     */
    public static questionsControllerGet(
        uuid: string,
    ): CancelablePromise<Question> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questions/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns Question The updated question record
     * @throws ApiError
     */
    public static questionsControllerUpdate(
        uuid: string,
        requestBody: QuestionUpdateDto,
    ): CancelablePromise<Question> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/questions/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param pageUuid
     * @returns QuestionDto the questions on the page
     * @throws ApiError
     */
    public static questionsControllerGetQuestionnaireQuestions(
        pageUuid: string,
    ): CancelablePromise<Array<QuestionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/questions/page/{pageUuid}',
            path: {
                'pageUuid': pageUuid,
            },
        });
    }
    /**
     * @param questionnaireUuid
     * @param revision
     * @returns SummarizedRange The scored item min/max score ranges, summed for the full questionnaire
     * @throws ApiError
     */
    public static revisionsControllerGetQuestionnaireScoreRangeTotals(
        questionnaireUuid: string,
        revision: number,
    ): CancelablePromise<Array<SummarizedRange>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/revisions/score-ranges/totals/questionnaire/{questionnaireUuid}/{revision}',
            path: {
                'questionnaireUuid': questionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @param questionnaireUuid
     * @returns RevisionList The revisions that exist for a questionnaire
     * @throws ApiError
     */
    public static revisionsControllerGetQuestionnaireRevisions(
        questionnaireUuid: string,
    ): CancelablePromise<RevisionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/revisions/questionnaire/{questionnaireUuid}',
            path: {
                'questionnaireUuid': questionnaireUuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns RevisionCreationJob The task to create the revision
     * @returns any
     * @throws ApiError
     */
    public static revisionsControllerCreateRevision(
        requestBody: CreateRevisionDto,
    ): CancelablePromise<RevisionCreationJob | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/revisions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param questionnaireUuid
     * @param revision
     * @returns QuestionScoreRangesDto calculate and return score ranges for questionnaire revision
     * @throws ApiError
     */
    public static revisionsControllerCalculateForQuestionnaireRevision(
        questionnaireUuid: string,
        revision: number,
    ): CancelablePromise<Array<QuestionScoreRangesDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/revisions/score-ranges/questionnaire/{questionnaireUuid}/{revision}',
            path: {
                'questionnaireUuid': questionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @returns ScoredItemExportDto Export all score items
     * @throws ApiError
     */
    public static scoredItemsControllerExport(): CancelablePromise<Array<ScoredItemExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scored-items/export',
        });
    }
    /**
     * @param uuid
     * @returns ScoredItem The scored item record
     * @throws ApiError
     */
    public static scoredItemsControllerGet(
        uuid: string,
    ): CancelablePromise<ScoredItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scored-items/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param requestBody
     * @returns ScoredItem The updated scored item
     * @throws ApiError
     */
    public static scoredItemsControllerUpdate(
        uuid: string,
        requestBody: ScoredItemUpdateDto,
    ): CancelablePromise<ScoredItem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/scored-items/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param questionnaireUuid
     * @param revision
     * @returns ScoredItem The scored items for the questionnaire
     * @throws ApiError
     */
    public static scoredItemsControllerGetQuestionnaireScoredItems(
        questionnaireUuid: string,
        revision: string,
    ): CancelablePromise<Array<ScoredItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scored-items/questionnaire/{questionnaireUuid}/revision/{revision}',
            path: {
                'questionnaireUuid': questionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ScoredItem
     * @throws ApiError
     */
    public static scoredItemsControllerCreate(
        requestBody: ScoredItemCreateDto,
    ): CancelablePromise<ScoredItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scored-items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ScoreCategory all score categories
     * @throws ApiError
     */
    public static scoreCategoriesControllerList(): CancelablePromise<Array<ScoreCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/score-categories',
        });
    }
    /**
     * @param questionUuid
     * @param requestBody
     * @returns Score The created score records
     * @throws ApiError
     */
    public static scoresControllerAutoScoreAnswers(
        questionUuid: string,
        requestBody: AutoScoreDto,
    ): CancelablePromise<Array<Score>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scores/question/{questionUuid}/auto/{direction}',
            path: {
                'questionUuid': questionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns Score The created score record
     * @throws ApiError
     */
    public static scoresControllerCreate(
        requestBody: ScoreCreateDto,
    ): CancelablePromise<Score> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scores',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param answerUuid
     * @returns Score the score records for the specified answer
     * @throws ApiError
     */
    public static scoresControllerGetAnswerScores(
        answerUuid: string,
    ): CancelablePromise<Array<Score>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scores/answer/{answerUuid}',
            path: {
                'answerUuid': answerUuid,
            },
        });
    }
    /**
     * @param questionUuid
     * @returns Score the score records for the specified question
     * @throws ApiError
     */
    public static scoresControllerGetQuestionScores(
        questionUuid: string,
    ): CancelablePromise<Array<Score>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scores/question/{questionUuid}',
            path: {
                'questionUuid': questionUuid,
            },
        });
    }
    /**
     * @param pageUuid
     * @returns Score the score records for the specified question
     * @throws ApiError
     */
    public static scoresControllerGetPageScores(
        pageUuid: string,
    ): CancelablePromise<Array<Score>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scores/page/{pageUuid}',
            path: {
                'pageUuid': pageUuid,
            },
        });
    }
    /**
     * @param uuid
     * @returns Score the score record
     * @throws ApiError
     */
    public static scoresControllerGet(
        uuid: string,
    ): CancelablePromise<Score> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scores/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @param uuid
     * @param score
     * @returns any
     * @throws ApiError
     */
    public static scoresControllerSetScore(
        uuid: string,
        score: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/scores/{uuid}',
            path: {
                'uuid': uuid,
            },
            query: {
                'score': score,
            },
        });
    }
    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static scoresControllerRemove(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scores/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * @returns ScoreRangesExportDto Export all score ranges
     * @throws ApiError
     */
    public static scoreRangesControllerExport(): CancelablePromise<Array<ScoreRangesExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/score-ranges/export',
        });
    }
    /**
     * @param questionnaireUuid
     * @param revision
     * @returns SummarizedRange The scored items for the questionnaire
     * @throws ApiError
     */
    public static scoreRangesControllerGetQuestionnaireScoredItems(
        questionnaireUuid: string,
        revision: number,
    ): CancelablePromise<Array<SummarizedRange>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/score-ranges/totals/questionnaire/{questionnaireUuid}/revision/{revision}',
            path: {
                'questionnaireUuid': questionnaireUuid,
                'revision': revision,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static pageOrderControllerSetQuestionOrder(
        requestBody: QuestionMoveDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/question',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static pageOrderControllerSetPageOrder(
        requestBody: PageMoveDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/page',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static pageOrderControllerSetChapterOrder(
        requestBody: ChapterMoveDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/chapter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns Question created question records populated with their sample data
     * @throws ApiError
     */
    public static templatesControllerCreateSample(
        requestBody: SampleQuestionCreateDto,
    ): CancelablePromise<Array<Question>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/templates/question',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ResponseDto Export all response answers
     * @throws ApiError
     */
    public static responseAnswersControllerFindAll(): CancelablePromise<Array<ResponseDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/response-answers/export',
        });
    }
    /**
     * @param requestBody
     * @returns any takes a survey.js response, parses it and saves to response-answers
     * @throws ApiError
     */
    public static responseAnswersControllerPostAnswers(
        requestBody: ResponseAnswersCreateDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/response-answers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ResponseScoresExportDto Export all response scores
     * @throws ApiError
     */
    public static responseScoresControllerExport(): CancelablePromise<Array<ResponseScoresExportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/response-scores/export',
        });
    }
    /**
     * @param responseUuid
     * @returns ResponseSummaryDto calculate full scores for response and return them
     * @throws ApiError
     */
    public static responseScoresControllerGetResponseSummary(
        responseUuid: string,
    ): CancelablePromise<ResponseSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/response-scores/response/{responseUuid}/summary',
            path: {
                'responseUuid': responseUuid,
            },
        });
    }
    /**
     * @param responseUuid
     * @returns ResponseScoreDto calculate full scores for response and return them
     * @throws ApiError
     */
    public static responseScoresControllerCalculateScores(
        responseUuid: string,
    ): CancelablePromise<Array<ResponseScoreDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/response-scores/response/{responseUuid}',
            path: {
                'responseUuid': responseUuid,
            },
        });
    }
    /**
     * @returns EmailDto get emails
     * @throws ApiError
     */
    public static marketingControllerProvideEmails(): CancelablePromise<Array<EmailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/marketing/emails',
        });
    }
    /**
     * @returns MarketingContactDto get contact emails
     * @throws ApiError
     */
    public static marketingControllerProvideContacts(): CancelablePromise<Array<MarketingContactDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/marketing/contacts',
        });
    }
    /**
     * @param emailUuid
     * @returns EmailDto get email
     * @throws ApiError
     */
    public static marketingControllerProvideEmail(
        emailUuid: string,
    ): CancelablePromise<EmailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/marketing/email/{emailUuid}',
            path: {
                'emailUuid': emailUuid,
            },
        });
    }
    /**
     * @param emailUuid
     * @param requestBody
     * @returns EmailCommunicationEntity change email text
     * @returns any
     * @throws ApiError
     */
    public static marketingControllerUpdateEmail(
        emailUuid: string,
        requestBody: EmailUpdateDto,
    ): CancelablePromise<EmailCommunicationEntity | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/marketing/email/{emailUuid}',
            path: {
                'emailUuid': emailUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param emailUuid
     * @param requestBody
     * @returns any send email to participants
     * @throws ApiError
     */
    public static marketingControllerSendEmail(
        emailUuid: string,
        requestBody: EmailContactsDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/marketing/email/{emailUuid}/send',
            path: {
                'emailUuid': emailUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param unsubscribeRequestUuid
     * @returns any unsubscribe from marketing emails
     * @throws ApiError
     */
    public static marketingControllerUnsubscribe(
        unsubscribeRequestUuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/marketing/unsubscribe/{unsubscribeRequestUuid}',
            path: {
                'unsubscribeRequestUuid': unsubscribeRequestUuid,
            },
        });
    }
    /**
     * @returns any resend all failed
     * @throws ApiError
     */
    public static marketingControllerResend(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/marketing/resend',
        });
    }
}
